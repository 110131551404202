import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

type Props = {
  options: string[];
  value: string;
  placeholder: string;
  onChange: (v: any) => any;
  multiple?: boolean;
};

const SelectComponent: FC<Props> = ({
  options,
  value,
  placeholder,
  onChange,
  multiple = false,
  ...rest
}) => (
  <div>
    <FormControl>
      <InputLabel id="demo-simple-select-helper-label">
        {placeholder}
      </InputLabel>

      <Select
        multiple={multiple}
        value={value}
        onChange={onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        label={placeholder}
        {...rest}
      >
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default SelectComponent;
