import React, { FC } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import {
  ButtonStyled,
  Content,
  Input,
  Line,
  Multiple,
  SelectStyled,
  Title,
} from './styles';

type Props = {};

const Contact: FC<Props> = () => {
  const {
    values,
    handleChange,
    errors,
    submitForm,
    setFieldValue,
  }: FormikProps<BriefingType> = useFormikContext();
  return (
    <Content>
      <Title>Briefing para preenchimento</Title>
      <Input
        name="business_name"
        value={values.business_name}
        onChange={handleChange}
        error={!!errors.business_name}
        placeholder="Nome da empresa"
        fullWidth
      />

      <Line>
        <Input
          name="phone"
          value={values.phone.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3')}
          onChange={handleChange}
          error={!!errors.phone}
          placeholder="Telefone"
        />
        <Input
          name="whatsapp"
          value={values.whatsapp.replace(
            /^(\d{2})(\d{5})(\d{4}).*/,
            '($1) $2-$3',
          )}
          onChange={handleChange}
          error={!!errors.whatsapp}
          placeholder="WhatsApp"
        />
        <Input
          name="email"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          placeholder="Email"
        />
      </Line>

      <Line>
        <Input
          name="website"
          value={values.website}
          onChange={handleChange}
          error={!!errors.website}
          placeholder="Site"
        />
        <Input
          name="responsible"
          value={values.responsible}
          onChange={handleChange}
          error={!!errors.responsible}
          placeholder="Responsável"
        />
        <Input
          name="address"
          value={values.address}
          onChange={handleChange}
          error={!!errors.address}
          placeholder="Endereço"
        />
      </Line>

      <Line>
        <Input
          name="feira"
          value={values.feira}
          onChange={handleChange}
          error={!!errors.feira}
          placeholder="Feira"
        />
        <Input
          name="size_stands"
          value={values.size_stands}
          onChange={handleChange}
          error={!!errors.size_stands}
          placeholder="Tamanho do stand: 10x10"
        />
        <Input
          name="number_stands"
          value={values.number_stands}
          onChange={handleChange}
          error={!!errors.number_stands}
          placeholder="Número do stand"
        />
      </Line>

      <Line>
        <SelectStyled
          value={values.type_area}
          onChange={handleChange('type_area')}
          placeholder="Tipo de area"
          options={['Esquina', 'Ilha', 'Box', 'Corredor', 'Ponta de ilha']}
        />

        <SelectStyled
          value={values.type_stands}
          onChange={handleChange('type_stands')}
          placeholder="Tipo de Stand"
          options={['Aberto', 'Fechado', 'Semi aberto']}
        />

        <SelectStyled
          value={values.system_construction}
          onChange={handleChange('system_construction')}
          placeholder="Sistema de construção"
          options={['Padrão', 'Construido', 'Misto']}
        />
      </Line>

      <Line>
        <Multiple
          values={values.company_need}
          onChange={(v) => {
            setFieldValue('company_need', v.target.value);
          }}
          placeholder="Necessidades da empresa"
          options={[
            'Balcões',
            'Uma sala',
            'Duas salas',
            'Três salas',
            'Copa',
            'Deposito',
            'Prateleiras',
            'Produtos expostos em vitrines',
            'Produtos expostos em displays',
            'Produtos expostos em prateleiras de madeira',
            'Produtos expostos em prateleiras de vidro',
          ]}
        />

        <Input
          name="budget"
          value={values.budget}
          onChange={handleChange}
          error={!!errors.budget}
          placeholder="Verba para a montagem"
        />

        <Input
          name="date"
          value={values.date.replace(/^(\d{2})(\d{2})(\d{4}).*/, '$1/$2/$3')}
          onChange={handleChange}
          error={!!errors.date}
          placeholder="Data da feira"
        />
      </Line>

      <Input
        name="complement_briefing"
        value={values.complement_briefing}
        onChange={handleChange}
        error={!!errors.complement_briefing}
        placeholder="Complemento  do briefing"
        fullWidth
      />
      <ButtonStyled variant="contained" onClick={submitForm}>
        Enviar
      </ButtonStyled>
    </Content>
  );
};

export default Contact;
