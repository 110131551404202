import React, { FC } from 'react';
import { Component, IconClose, SectionContent, Wrapper } from './styles';

type Props = {
  title?: string;
  visible?: boolean;
  children?: React.ReactElement;
  onClose?: () => void;
};

const Modal: FC<Props> = ({ children, title, onClose, visible = false }) => (
  <Component open={visible} onClose={onClose}>
    <Wrapper>
      <IconClose onPress={onClose} />
      <SectionContent>{children}</SectionContent>
    </Wrapper>
  </Component>
);

export default Modal;
