import React, { FC } from 'react';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import { bannerImg } from '~/assets/img';
import { Instagram } from '~/assets/svg';
import { Contact, Gallery, If, Menu, Modal } from '~/components';
import {
  Content,
  ContentView,
  Facebook,
  Image,
  Linkedin,
  Logo,
  Text,
  Title,
  Wrapper,
  WrapperIcon,
} from './styles';
import 'react-whatsapp-widget/dist/index.css';

type Props = {
  onStands: () => void;
  onProject: () => void;
  onBusiness: () => void;
  onBriefing: () => void;
  onContact: () => void;
  onModal: () => void;
  onCloseModal: () => void;
  modal: boolean;
  images: string[];
  modalDescription: string[];
  modalTitle: string;
  showModalContent: boolean;
  briefing: boolean;
};

const Home: FC<Props> = ({
  onStands,
  onProject,
  onBusiness,
  onBriefing,
  onContact,
  onModal,
  modal,
  images,
  modalDescription,
  showModalContent,
  modalTitle,
  onCloseModal,
  briefing,
}): JSX.Element => (
  <>
    <Wrapper>
      <Content>
        <Image src={bannerImg} />
        <Menu
          onStands={onStands}
          onProject={onProject}
          onBusiness={onBusiness}
          onBriefing={onBriefing}
          onContact={onContact}
        />
      </Content>
      <Logo />
    </Wrapper>

    <If condition={modal}>
      <Modal visible={modal} onClose={onCloseModal}>
        <>
          <If condition={!!images[0]}>
            <Gallery images={images} />
          </If>
          <If condition={showModalContent && modal}>
            <ContentView>
              <Title>{modalTitle}</Title>
              {modalDescription.map((description) => (
                <Text key={description}>{description}</Text>
              ))}

              <If condition={modalTitle === 'Contato'}>
                <WrapperIcon>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/formatho"
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>

                  <a
                    target="_blank"
                    href="https://www.instagram.com/formathodesignproducoes/"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>

                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/formatho-design-produ%C3%A7%C3%B5es"
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                </WrapperIcon>
              </If>
            </ContentView>
          </If>

          <If condition={briefing}>
            <Contact />
          </If>
        </>
      </Modal>
    </If>
    <WhatsAppWidget
      companyName=""
      message="Olá, como podemos te ajudar?"
      phoneNumber="5512997316825"
      replyTimeText="Geralmente respondemos em uma hora"
    />
  </>
);

export default Home;
