import React, { FC } from 'react';
import MaterialIcon from '@material-ui/core/Icon';
import * as Icons from '~/assets/svg';
import { If } from '~/components';
import { Touch } from './styles';

type IconSize = 'inherit' | 'default' | 'small' | 'large';

type IconColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'action'
  | 'error'
  | 'disabled';

type Props = {
  name: any;
  size?: IconSize;
  color?: IconColor;
  onPress?: () => void;
};

const Icon: FC<Props> = ({
  name,
  color = 'inherit',
  size = 'default',
  onPress = () => {},
  ...rest
}) => {
  const icons: Record<string, any> = Icons;
  const Component = icons[name];

  return (
    <Touch onClick={onPress}>
      <If condition={!!Component}>
        <Component {...rest} />
      </If>

      <If condition={!Component}>
        <MaterialIcon fontSize="medium" color={color} {...rest}>
          {name}
        </MaterialIcon>
      </If>
    </Touch>
  );
};

export default Icon;
