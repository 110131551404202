import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');

// Colors
const secondaryContrast = getTheme('secondary.contrast');

// Spacing
const mediumSpacing = getTheme('mediumSpacing');

export const Wrapper = styled.div`
  margin: ${mediumSpacing}px auto;
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;

  @media ${inMobile} {
    width: 100%;
    height: 35vh;
  }
`;

export const Section = styled(Carousel)<any>`
  .rec.rec-arrow {
    box-shadow: none;
    background: white;
  }

  .rec-carousel-item:focus {
    box-shadow: none;
  }

  .rec.rec-arrow:hover {
    background: ${secondaryContrast};
  }

  button {
    box-shadow: none;

    @media ${inMobile} {
      font-size: ${pxToRem(15)};
      min-width: ${pxToRem(30)};
      height: ${pxToRem(35)};
      line-height: 0;
      border: 0;
      margin: 0;
    }
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 700px;
  height: 450px;
  margin: 10px;

  @media ${inMobile} {
    width: 100%;
    height: 30vh;
  }
`;
