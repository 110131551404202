import React, { FC } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

type Props = {
  values: string[];
  placeholder: string;
  onChange: (v: any) => any;
  options: string[];
  selected?: string[];
};

const MultipleSelectCheckmarks: FC<Props> = ({
  placeholder,
  values = [],
  onChange,
  options,
  selected = [],
  ...rest
}) => (
  <div>
    <FormControl>
      <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
      <Select
        multiple
        value={values}
        onChange={onChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        {...rest}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={values.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default MultipleSelectCheckmarks;
