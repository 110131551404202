import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { getTheme, pxToRem } from '~/utils';

const primaryLight = getTheme('primary.light');
const minimumSpacing = getTheme('minimumSpacing');

export const Input = styled(TextField)`
  & label {
    font-size: 1em;
  }
  & .MuiOutlinedInput-root {
    height: ${pxToRem(40)};
    background: ${primaryLight};
    margin: ${minimumSpacing};
  }
`;
