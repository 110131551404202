import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { Typography } from '~/components';
import { getTheme } from '~/utils';
// Colors
const primaryLight = getTheme('primary.light');

// Spacings
const giantSpacing = getTheme('giantSpacing');
const largeSpacing = getTheme('largeSpacing');

// breakpoints
const inMobile = getTheme('inMobile');

export const Wrapper = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${inMobile} {
    width: 100%;
    align-items: center;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'h3',
})`
  color: ${primaryLight};
  text-align: right;
`;

export const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    margin-bottom: ${giantSpacing};

    @media ${inMobile} {
      margin: ${largeSpacing} 0;
    }
  }
`;
