import React, { FC } from 'react';
import { Button, Title, Wrapper } from './styles';

type Props = {
  onStands: () => void;
  onProject: () => void;
  onBusiness: () => void;
  onBriefing: () => void;
  onContact: () => void;
};

const Menu: FC<Props> = ({
  onStands,
  onProject,
  onBusiness,
  onBriefing,
  onContact,
}) => (
  <Wrapper>
    <Button onClick={onStands}>
      <Title>Stands</Title>
    </Button>

    <Button onClick={onProject}>
      <Title>Projetos</Title>
    </Button>

    <Button onClick={onBusiness}>
      <Title>Empresa</Title>
    </Button>

    {/* <Button onClick={onBriefing}>
      <Title>Briefing</Title>
    </Button> */}

    <Button onClick={onContact}>
      <Title>Contato</Title>
    </Button>
  </Wrapper>
);

export default Menu;
