import * as yup from 'yup';

export const initialValues = {
  business_name: '',
  phone: '',
  whatsapp: '',
  email: '',
  responsible: '',
  website: '',
  feira: '',
  address: '',
  number_stands: '',
  size_stands: '',
  type_area: '',
  system_construction: '',
  type_stands: '',
  budget: '',
  company_need: [],
  complement_briefing: '',
  date: '',
};

export const validateForm = yup.object().shape({
  business_name: yup.string().required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  whatsapp: yup.string().required('Campo obrigatório'),
  email: yup.string().email().required('Campo obrigatório'),
  responsible: yup.string().required('Campo obrigatório'),
  website: yup.string().required('Campo obrigatório'),
  feira: yup.string().required('Campo obrigatório'),
  date: yup.string().required('Campo obrigatório'),
  address: yup.string().required('Campo obrigatório'),
  number_stands: yup.string().required('Campo obrigatório'),
  size_stands: yup.string().required('Campo obrigatório'),
  type_area: yup.string().required('Campo obrigatório'),
  system_construction: yup.string().required('Campo obrigatório'),
  type_stands: yup.string().required('Campo obrigatório'),
  budget: yup.number().required('Campo obrigatório'),
  company_need: yup.array().required('Campo obrigatório'),
  complement_briefing: yup.string().required('Campo obrigatório'),
});
