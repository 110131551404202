import React, { FC } from 'react';
import { device } from '~/theme/breakpoints';
import { Image, Section, Wrapper } from './styles';

type Props = {
  images: string[];
};

const Gallery: FC<Props> = ({ images }) => (
  <Wrapper>
    <Section
      isRTL
      pagination={false}
      enableSwipe={device.isMobile}
      initialFirstItem={images.length}
      initialActiveIndex={0}
      breakPoints={[{ width: 0, itemsToShow: 1 }]}
    >
      {images.map((image) => (
        <Image src={image} />
      ))}
    </Section>
  </Wrapper>
);

export default Gallery;
