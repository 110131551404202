import React, { FC, useState } from 'react';
import axios from 'axios';
import { Formik, FormikProps } from 'formik';
import { projects_images, stands_images } from '~/assets/img';
import { alert } from '~/utils';
import { initialValues, validateForm } from './form';
import Home from './Home';

const HomeContainer: FC = (): JSX.Element => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    description: [''],
    open: false,
  });
  const [images, setImages] = useState(['']);
  const [briefing, setBriefing] = useState(false);

  const onImages = (image: string[]) => setImages(image);

  const onOpenModal = () => setModal(true);

  const onCloseModal = () => {
    setImages(['']);
    setModal(false);
    setBriefing(false);
    setModalContent({
      title: '',
      description: [''],
      open: false,
    });
  };

  const onStands = () => {
    onImages(stands_images);
    onOpenModal();
  };

  const onProject = () => {
    onImages(projects_images);
    onOpenModal();
  };

  const onBusiness = () => {
    onOpenModal();
    setModalContent({
      open: true,
      title: 'Arquitetura Promocional para promover bons negócios',
      description: [
        '• Com foco em qualidade e pontualidade, temos como missão proporcionar a experiência do design inovador para agregar valor aos negócios de nossos clientes.',
        '• Nos destacamos pelo olhar único sobre cada produção. Nossa metodologia consiste em combinar conceito e estratégia a soluções arrojadas, elegantes e funcionais para todo tipo de evento ou espaço.',
        '• Acreditamos em parcerias de sucesso, compromisso, responsabilidade e dedicação para obtermos sempre os melhores resultados possíveis. . Com mais de 16 anos de atuação em todo Brasil, atendemos empresas de praticamente todos os setores e segmentos da economia.',
        '• Conheça nosso trabalho. Estamos dispostos e energizados para desenvolver resultados que farão a diferença para seu negócio ou evento.',
      ],
    });
  };
  const onBriefing = () => {
    onOpenModal();
    setBriefing(true);
  };

  const onContact = () => {
    onOpenModal();
    setModalContent({
      open: true,
      title: 'Contato',
      description: [
        'Telefone Comercial: 12 99731-6825 \n',
        'Email: formathodesignproducoes@gmail.com \n',
        'Telefone para projetos: 12 99608-6997 \n',
      ],
    });
  };

  const onSubmit = async (
    {
      address,
      type_stands,
      system_construction,
      budget,
      business_name,
      company_need,
      complement_briefing,
      date,
      email,
      feira,
      number_stands,
      phone,
      responsible,
      size_stands,
      type_area,
      website,
      whatsapp,
    }: any,
    { resetForm }: any,
  ): Promise<any> => {
    try {
      await axios.post('https://services-sendings-mails.herokuapp.com', {
        to: 'lstands@terra.com.br',
        subject: business_name,
        title: 'Briefing',
        html: `
        <table>
  <tr> <td style="border-bottom: 1px solid #ddd;">Nome da empresa: </td> <td style="border-bottom: 1px solid #ddd;">${business_name}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Telefone: </td> <td style="border-bottom: 1px solid #ddd;">${phone.replace(
    /^(\d{2})(\d{4})(\d{4}).*/,
    '($1) $2-$3',
  )}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">WhatsApp: </td> <td style="border-bottom: 1px solid #ddd;">${whatsapp.replace(
    /^(\d{2})(\d{5})(\d{4}).*/,
    '($1) $2-$3',
  )}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Email: </td> <td style="border-bottom: 1px solid #ddd;">${email}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Responsável: </td> <td style="border-bottom: 1px solid #ddd;">${responsible}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Site: </td> <td style="border-bottom: 1px solid #ddd;">${website}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Feira: </td> <td style="border-bottom: 1px solid #ddd;">${feira}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Endereço: </td> <td style="border-bottom: 1px solid #ddd;">${address}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Número do Stand</td> <td style="border-bottom: 1px solid #ddd;">${number_stands}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Tamanho do Stand</td> <td style="border-bottom: 1px solid #ddd;">${size_stands}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Tipo de Área</td> <td style="border-bottom: 1px solid #ddd;">${type_area}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Sistema de Construtivo</td> <td style="border-bottom: 1px solid #ddd;">${system_construction}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Tipo de Stand</td> <td style="border-bottom: 1px solid #ddd;">${type_stands}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Verba para a montagem: </td> <td style="border-bottom: 1px solid #ddd;">${budget}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Necessidades da empresa: </td> <td style="border-bottom: 1px solid #ddd;">${company_need.join(
    ', ',
  )}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Complemento  do briefing</td> <td style="border-bottom: 1px solid #ddd;">${complement_briefing}</td> </tr>
  <tr> <td style="border-bottom: 1px solid #ddd;">Data da Feira: </td> <td style="border-bottom: 1px solid #ddd;">${date.replace(
    /^(\d{2})(\d{2})(\d{4}).*/,
    '$1/$2/$3',
  )}</td> </tr>
  </table>`,
      });
      alert({
        message: 'Briefing enviado com sucesso!',
        type: 'info',
      });
      resetForm();
    } catch (error) {
      alert({
        message: 'Ocorreu um erro, tente novamente mais tarde!',
        type: 'error',
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateForm}
      onSubmit={onSubmit}
    >
      <Home
        onStands={onStands}
        onProject={onProject}
        onBusiness={onBusiness}
        onBriefing={onBriefing}
        onContact={onContact}
        modal={modal}
        onModal={onOpenModal}
        images={images}
        modalDescription={modalContent.description}
        modalTitle={modalContent.title}
        showModalContent={modalContent.open}
        onCloseModal={onCloseModal}
        briefing={briefing}
      />
    </Formik>
  );
};
export default HomeContainer;
