import bannerImg from './banner.png';

// Projects

import facebook from './facebook.png';
import linkedin from './linkedin.png';
import logoImg from './logo.png';

import projects0 from './projects_0.jpg';

import projects1 from './projects_1.jpg';
import projects10 from './projects_10.jpg';
import projects11 from './projects_11.jpg';
import projects12 from './projects_12.jpg';
import projects2 from './projects_2.jpg';
import projects3 from './projects_3.jpg';
import projects4 from './projects_4.jpg';
import projects5 from './projects_5.jpg';
import projects6 from './projects_6.jpg';
import projects7 from './projects_7.jpg';
import projects8 from './projects_8.jpg';
import projects9 from './projects_9.jpg';

// Stands

import stands0 from './stands_0.jpg';
import stands1 from './stands_1.jpg';
import stands10 from './stands_10.jpg';
import stands11 from './stands_11.jpg';
import stands12 from './stands_12.jpg';
import stands13 from './stands_13.jpg';
import stands14 from './stands_14.jpg';
import stands15 from './stands_15.jpg';
import stands16 from './stands_16.jpg';
import stands17 from './stands_17.jpg';
import stands18 from './stands_18.jpg';
import stands19 from './stands_19.jpg';
import stands2 from './stands_2.jpg';
import stands20 from './stands_20.jpg';
import stands21 from './stands_21.jpg';
import stands22 from './stands_22.jpg';
import stands23 from './stands_23.jpg';
import stands24 from './stands_24.jpg';
import stands25 from './stands_25.jpg';
import stands26 from './stands_26.jpg';
import stands27 from './stands_27.jpg';
import stands28 from './stands_28.jpg';
import stands29 from './stands_29.jpg';
import stands3 from './stands_3.jpg';
import stands30 from './stands_30.jpg';
import stands31 from './stands_31.jpg';
import stands32 from './stands_32.jpg';
import stands33 from './stands_33.jpg';
import stands34 from './stands_34.jpg';
import stands35 from './stands_35.jpg';
import stands36 from './stands_36.jpg';
import stands4 from './stands_4.jpg';
import stands5 from './stands_5.jpg';
import stands6 from './stands_6.jpg';
import stands7 from './stands_7.jpg';
import stands8 from './stands_8.jpg';
import stands9 from './stands_9.jpg';

export { bannerImg, logoImg, facebook, linkedin };

export const projects_images = [
  projects0,
  projects1,
  projects2,
  projects3,
  projects4,
  projects5,
  projects6,
  projects7,
  projects8,
  projects9,
  projects10,
  projects11,
  projects12,
];

export const stands_images = [
  stands0,
  stands1,
  stands2,
  stands3,
  stands4,
  stands5,
  stands6,
  stands7,
  stands8,
  stands9,
  stands10,
  stands11,
  stands12,
  stands13,
  stands14,
  stands15,
  stands16,
  stands17,
  stands18,
  stands19,
  stands20,
  stands21,
  stands22,
  stands23,
  stands24,
  stands25,
  stands26,
  stands27,
  stands28,
  stands29,
  stands30,
  stands31,
  stands32,
  stands33,
  stands34,
  stands35,
  stands36,
];
