import styled from 'styled-components';
import { facebook, linkedin, logoImg } from '~/assets/img';
import { Typography } from '~/components';
import { getTheme, pxToRem } from '~/utils';

// breakpoints
const inMobile = getTheme('inMobile');
const inDesktop = getTheme('inDesktop');

// Colors
const primaryMain = getTheme('primary.main');
const secondaryMain = getTheme('secondary.main');

// Spacing
const mediumSpacing = getTheme('mediumSpacing');

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${mediumSpacing};
`;

export const Image = styled.img`
  width: 85%;
  height: 85%;

  @media ${inMobile} {
    width: 90vw;
    height: ${pxToRem(300)};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${inMobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${inDesktop} {
    overflow: hidden;
  }
`;

export const Logo = styled.img.attrs({ src: logoImg })`
  @media ${inDesktop} {
    position: absolute;
    bottom: ${pxToRem(0)};
    right: -${pxToRem(10)};
    width: 25%;
  }

  @media ${inMobile} {
    width: 100%;
  }
`;

export const ContentView = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${mediumSpacing};
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  color: ${secondaryMain};
`;

export const Text = styled(Typography).attrs({
  variant: 'body1',
})`
  padding-top: ${mediumSpacing};
`;

export const WrapperIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: ${pxToRem(30)};
`;

export const Facebook = styled.img.attrs({
  src: facebook,
})`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
`;

export const Linkedin = styled.img.attrs({
  src: linkedin,
})`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
`;
