export default {
  failure: '#E56657',
  warning: '#FABD59',
  info: '#2D9CDB',
  success: '#27AE60',

  disabled: {
    light: '#ffffff',
    main: '#fff',
    dark: '#fff',
    contrast: '#4f4f4f',
  },
  primary: {
    light: '#fafafa',
    main: '#1b0775',
    dark: '#fff',
    contrast: '#4f4f4f',
  },
  secondary: {
    light: '#fff',
    main: '#001f61',
    dark: '#fff',
    contrast: '#ffffff',
  },
  tertiary: {
    light: '#fff',
    main: '#fff',
    dark: '#fff',
    contrast: '#FFFFFF',
  },
  accent: {
    light: '#fff',
    main: '#fff',
    dark: '#fff',
    contrast: '#ffffff',
  },
} as ColorType;
