import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import { Icon, Typography } from '~/components';
import { breakpoints } from '~/theme';
import { getTheme, pxToRem } from '~/utils';

// Colors
const primaryContrast = getTheme('brand.primary.contrast');
const secondaryDark = getTheme('brand.secondary.dark');
const primaryLight = getTheme('primary.light');

// Spacing
const spacingMd = getTheme('spacing.md');
const spacingSm = getTheme('spacing.sm');

// Radius
const smallRadius = getTheme('smallRadius');

export const Component = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  min-width: ${pxToRem(300)};
  max-width: 100%;
  min-height: ${pxToRem(300)};
  max-height: 80vh;
  border-radius: ${smallRadius};
  background: ${primaryLight};
  padding: ${spacingMd}px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: auto;

  @media ${breakpoints.inMobile} {
    min-width: 98%;
    max-width: 98%;
    width: 98%;
    min-height: ${pxToRem(100)};
    max-height: 95%;
    height: auto;
  }
`;

export const IconClose = styled(Icon).attrs({
  name: 'close',
})`
  color: ${secondaryDark};
`;

export const SectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: ${spacingMd}px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Title = styled(Typography).attrs({
  variant: 'body1',
})`
  font-weight: 500;
  margin-bottom: ${spacingSm}px;
`;

export const Content = styled(Typography).attrs({
  variant: 'body2',
})`
  padding: ${spacingSm}px 0;
`;

export const PlayerVideo = styled.iframe`
  width: 100%;
  height: ${pxToRem(400)};
`;
