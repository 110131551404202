import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { breakpoints } from '~/theme';
import { getTheme, pxToRem } from '~/utils';
import { Select } from '../index';
import MultiSelect from '../MultiSelect';
import TextField from '../TextField';
import Typography from '../Typography';

// Colors
const failure = getTheme('failure');

// Spacings
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${mediumSpacing};
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${breakpoints.inMobile} {
    flex-direction: column;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
})`
  color: ${failure};
`;

export const Input = styled(TextField)`
  margin: 10px;
  padding: 10px;
  &.MuiOutlinedInput-root {
    margin: ${smallSpacing};
  }

  &.MuiInputBase-root {
    margin: ${smallSpacing};
  }

  @media ${breakpoints.inMobile} {
    width: 100%;
  }
`;

export const SelectStyled = styled(Select)`
  width: ${pxToRem(245)};
  height: ${pxToRem(40)};

  margin: 10px 0;

  .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media ${breakpoints.inMobile} {
    width: 85vw;
  }
`;

export const ButtonStyled = styled(Button)`
  &.MuiButton-root {
    margin-top: ${smallSpacing};
  }
  height: ${pxToRem(50)};
`;

export const Multiple = styled(MultiSelect)`
  width: ${pxToRem(245)};
  height: ${pxToRem(40)};
`;
